import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Box } from '@mui/material';
import PullToRefresh from 'pulltorefreshjs';
import Login from './components/Login';
import Register from './components/Register';
import Team from './components/Team';
import BottomNav from './components/BottomNav';
import theme from './theme';
import './App.css';
import SideMenu from './components/SideMenu';

// Placeholder components for new routes
const Rankings = () => <Box sx={{ mb: 7 }}>Classifiche</Box>;
const Reports = () => <Box sx={{ mb: 7 }}>Segnalazioni</Box>;
const Rules = () => <Box sx={{ mb: 7 }}>Regolamento</Box>;

function App() {
  const isLoggedIn = !!localStorage.getItem('token');

  useEffect(() => {
    const ptr = PullToRefresh.init({
      mainElement: 'body',
      distThreshold: 150,
      distMax: 200,
      iconArrow: '↓',
      iconRefreshing: '↻',
      refreshTimeout: 300,
      instructionsPullToRefresh: "Tira per aggiornare",
      instructionsReleaseToRefresh: "Rilascia per aggiornare",
      instructionsRefreshing: "Aggiornamento...",
      onRefresh() {
        return new Promise((resolve) => {
          // Clear team data cache
          localStorage.removeItem('teamData');
          localStorage.removeItem('teamDataTimestamp');
          window.location.reload();
          resolve();
        });
      },
      classPrefix: 'ptr--'
    });

    return () => {
      ptr.destroy();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router basename="/app">
        {isLoggedIn && (
          <>
            <SideMenu />
            <BottomNav />
          </>
        )}
        <Box sx={{ 
          pb: 8,
          pt: 8,
          minHeight: '100vh'
        }}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/team" element={<Team />} />
            <Route path="/rankings" element={<Rankings />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/prices" element={<Box>Listino</Box>} />
            <Route path="/faq" element={<Box>FAQ</Box>} />
            <Route path="/private-league" element={<Box>Lega Privata</Box>} />
            <Route path="/social" element={<Box>Social</Box>} />
          </Routes>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
