import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box
} from '@mui/material';
import {
  Logout as LogoutIcon,
  Login as LoginIcon,
  MenuBook as MenuBookIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Group as GroupIcon,
  Share as ShareIcon,
  Home as HomeIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Header from './Header';

const SideMenu = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem('token');

  const handleAuthAction = () => {
    if (isLoggedIn) {
      localStorage.removeItem('token');
    }
    navigate('/');
  };

  const menuItems = [
    { text: 'Home', icon: <HomeIcon />, action: () => navigate('/') },
    { text: 'Listino', icon: <MenuBookIcon />, action: () => navigate('/prices') },
    { text: 'FAQ', icon: <QuestionAnswerIcon />, action: () => navigate('/faq') },
    { text: 'Lega privata', icon: <GroupIcon />, action: () => navigate('/private-league') },
    { text: 'Social', icon: <ShareIcon />, action: () => navigate('/social') },
    { 
      text: isLoggedIn ? 'Logout' : 'Login', 
      icon: isLoggedIn ? <LogoutIcon /> : <LoginIcon />, 
      action: handleAuthAction 
    },
  ];

  return (
    <>
      <Header onMenuClick={() => setOpen(true)} />
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            bgcolor: 'secondary.dark',
          }
        }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpen(false)}
        >
          <List>
            {menuItems.map((item) => (
              <ListItem button key={item.text} onClick={item.action}>
                <ListItemIcon sx={{ color: 'secondary.light' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={item.text} 
                  sx={{ color: 'secondary.light' }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default SideMenu;