import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Container, Grid, Card, CardContent, CardMedia, Avatar } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import PersonIcon from '@mui/icons-material/Person';
import api from '../services/api';

const CACHE_DURATION = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

const Team = () => {
  const [teamData, setTeamData] = useState({
    nome_s: '',
    punti: 0,
    morti: 0,
    crediti: 0,
    roster: []
  });
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTeamData = async () => {
      const cachedData = localStorage.getItem('teamData');
      const cachedTimestamp = localStorage.getItem('teamDataTimestamp');
      const now = Date.now();

      if (cachedData && cachedTimestamp && (now - parseInt(cachedTimestamp) < CACHE_DURATION)) {
        setTeamData(JSON.parse(cachedData));
        return;
      }

      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const response = await api.get('/wp-json/custom-api/v1/get-team', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.data || response.data.length === 0) {
          throw new Error('No team data found');
        }

        localStorage.setItem('teamData', JSON.stringify(response.data[0]));
        localStorage.setItem('teamDataTimestamp', now.toString());
        
        setTeamData(response.data[0]);
      } catch (err) {
        setError('Errore nel caricamento dei dati della squadra');
        console.error('Team data error:', err.message);
      }
    };

    fetchTeamData();
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <Typography component="h1" variant="h4" color="primary" align="center">
        {teamData.nome_s || 'Squadra'}
      </Typography>
      <Typography variant="body2" color="secondary.light" align="center">
        Classifica: {teamData.position}°/{teamData.total_teams}
      </Typography>
      <Paper elevation={3} sx={{ p: 1, mt: 1 }}>
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-around' }}>
          <Box color="primary.light">
            <Typography variant="body1">Punti</Typography>
            <Typography variant="h6">{teamData.punti}</Typography>
          </Box>
          <Box color="primary.light">
            <Typography variant="body1">Morti</Typography>
            <Typography variant="h6">{teamData.morti}</Typography>
          </Box>
          <Box color="primary.light">
            <Typography variant="body1">Crediti</Typography>
            <Typography variant="h6">{teamData.crediti} ₤</Typography>
          </Box>
        </Box>
      
        {error && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </Paper>
      <Grid container spacing={0} sx={{ mt: 1, mb: 4, borderRadius: 1, overflow: 'hidden' }}>
        {teamData.roster?.map((player) => {
          const birthDate = new Date(player.anno_n, player.mob, player.dob);
          const age = Math.floor((new Date() - birthDate) / (365.25 * 24 * 60 * 60 * 1000));
          
          return (
            <Grid item xs={12} sm={6} md={4} key={player.ID_mo}>
              <Card sx={{ height: '100%', display: 'flex', bgcolor: 'secondary.dark', position: 'relative', borderRadius: 0, borderBottom: 1, borderColor: 'secondary.light', gap: 0.5, padding: 1 }}>
                {player.capt === "1" && (
                  <StarsIcon 
                    color="warning" 
                    sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}
                  />
                )}
                {player.santino >= "1" ? (
                  <CardMedia
                    component="img"
                    sx={{ width: 90, height: '100%', objectFit: 'cover' }}
                    image={`https://www.fantamorto.org/santini/${player.id_wiki}.webp`}
                    alt={player.nome_m}
                  />
                ) : (
                  <Box sx={{ width: 90, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Avatar sx={{ width: 70, height: 70 }}>
                      <PersonIcon sx={{ fontSize: 50 }} />
                    </Avatar>
                  </Box>
                )}
                <CardContent sx={{ flexGrow: 1, p: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                    <Typography variant="subtitle1" component="div" color={player.deceduto === "1" ? "text.secondary" : "text.primary"}>
                      {player.nome_m}
                    </Typography>
                    {player.deceduto === "1" && <AirlineSeatIndividualSuiteIcon fontSize="small" color="error" />}
                  </Box>
                  <Typography variant="body1" color="text.secondary">
                    {age} anni
                  </Typography>
                  <Box sx={{ mt: 0.5, display: 'flex', justifyContent: 'space-between', fontSize: '1rem' }}>
                    <Typography variant="caption" color="secondary.light">
                      Punti<br />
                      {player.punti}
                    </Typography>
                    <Typography variant="caption" color="secondary.light">
                      Costo<br />
                      {player.crediti}₤
                    </Typography>
                    <Typography variant="caption" color="secondary.light">
                      Valore<br />
                      {player.nowcrediti}₤
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Team;