import { createTheme } from '@mui/material';
/*
--primary-100: #028895; card background
--primary-200: #51b8c6; accent card
--primary-300: #bcffff; card text
--accent-100: #7D3C98; purple accent
--accent-200: #ffcbff;
--text-100: #FFFFFF;
--text-200: #e0e0e0;
--bg-100: #321457; background purple #321457
--bg-200: #442469; header footer
--bg-300: #5f3d84;

*/
const theme = createTheme({
  palette: {
    //mode: 'dark',
    primary: {
      light: '#bcffff',
      main: '#51b8c6',
      dark: '#028895',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffcbff',
      main: '#7D3C98',
      dark: '#442469',
      contrastText: '#fff',
    },
    background: {
      default: '#321457',
      paper: '#028895'
    },
    text: {
      primary: '#ffffff',
      secondary: '#e0e0e0'
    }
  },
  typography: {
    fontFamily: 'DM Sans, sans-serif',
    h1: {
      fontFamily: 'Bahianita, cursive',
      fontSize: '3.5rem',
    },
    h4: {
      fontFamily: 'Bahianita, cursive',
      fontSize: '2.5rem',
    },
    h6: {
      fontFamily: 'Bahianita, cursive',
      fontSize: '2rem',
    }
  },
  components: {
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#fff'
          }
        }
      }
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#442469'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#321457'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#442469'
        }
      }
    }
  }
});

export default theme;