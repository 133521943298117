import React from 'react';
import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import GavelIcon from '@mui/icons-material/Gavel';

const BottomNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Paper 
      sx={{ 
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0,
        zIndex: 1100,
        backgroundColor: 'secondary.dark',
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingLeft: 'env(safe-area-inset-left)',
        paddingRight: 'env(safe-area-inset-right)',
        '& .MuiBottomNavigation-root': {
          paddingTop: 1,
          paddingBottom: 1
        }
      }} 
      elevation={4}
    >
      <BottomNavigation
        showLabels
        value={location.pathname}
        onChange={(event, newValue) => {
          navigate(newValue);
        }}
        //sx={{ backgroundColor: 'secondary.dark' }}
      >
        <BottomNavigationAction 
          label="Squadra" 
          value="/team" 
          icon={<GroupsIcon />}
          sx={{ color: 'secondary.light' }}
        />
        <BottomNavigationAction 
          label="Classifiche" 
          value="/rankings" 
          icon={<LeaderboardIcon />}
          sx={{ color: 'secondary.light' }}
        />
        <BottomNavigationAction 
          label="Segnalazioni" 
          value="/reports" 
          icon={<AnnouncementIcon />}
          sx={{ color: 'secondary.light' }}
        />
        <BottomNavigationAction 
          label="Regolamento" 
          value="/rules" 
          icon={<GavelIcon />}
          sx={{ color: 'secondary.light' }}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;