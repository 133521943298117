import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = ({ onMenuClick }) => {
  return (
    <AppBar 
      position="fixed" 
      color="transparent" 
      elevation={2}
      sx={{
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)', // For Safari support
        bgcolor: 'rgba(68, 36, 105, 0.8)', // secondary.dark with opacity
        height: { xs: '50px', sm: 'auto' },
        top: 0,
        left: 0,
        right: 0,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '-50px',
          left: 0,
          right: 0,
          height: '50px',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          bgcolor: 'rgba(68, 36, 105, 0.8)',
        }
      }}
    >
      <Toolbar sx={{ minHeight: '50px !important', px: 1, pt: { xs: 'env(safe-area-inset-top)', sm: 0 } }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <svg width="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
            <path fill="#f2e7de" d="M31.3 1S6.3-1.1.2 11.5c0 0 11.5-7.3 31.1-4.6v-6z"/>
            <path fill="#e6dbee" d="M31.3 4.6v2.3C11.7 4.2.2 11.5.2 11.5S6.8 3 31.3 4.6z"/>
            <path fill="#764e2a" d="M31.9 32.1h.4V1c0-.5-.4-.9-.8-.9-.5 0-.9.4-.9.8V32H32z"/>
            <path fill="#382e3d" stroke="#fffbf7" stroke-miterlimit="10" stroke-width=".2" d="m21.7 27.7-.2-3c2.2-1.7 3.5-4.4 3.5-7.4 0-6.6-4.3-15.3-19.3-12 5.1 2 .3 6.8.3 12s1.3 5.5 3.4 7.2L7.9 32h22v-.7c-4.5.6-7.2-2.3-8.2-3.7z"/>
            <path fill="#f2e7de" fill-rule="evenodd" d="M15.6 11.5c-5.9 0-7.2 3.3-7.4 4.4-.1.5-.2.9-.2 1.5 0 2.5 1.2 4.8 3.3 6.2v2.6h8.4v-2.6c2.1-1.4 3.3-3.7 3.3-6.2 0-.6 0-1.3-.2-1.9-.4-1.4-1.9-4-7.2-4z"/>
            <path fill="#cac1d1" d="M15.5 13.1c-6.7 0-7.4 3-7.4 3.1v-.3c.3-1.1 1.5-4.4 7.4-4.4s6.8 2.7 7.2 4c0 .2.1.5.2.8-.1-.2-.8-3.1-7.4-3.1z"/>
            <path fill="#4b3e53" d="M19.5 21c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-5.7 0c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"/>
            <path fill="#a19ba7" d="m19 26.2-.2-1.3c0-.1-.1-.2-.2-.2 0 0-.2 0-.2.2l-.2 1.3h.8zm-1.9 0-.2-1.3c0-.1-.1-.2-.2-.2 0 0-.2 0-.2.2l-.2 1.3h.8zm-1.9 0-.2-1.3c0-.1-.1-.2-.2-.2 0 0-.2 0-.2.2l-.2 1.3h.8z"/>
          </svg>
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ 
              ml: 2,
              fontFamily: 'Bahianita, cursive',
              fontSize: '2.1rem',
              color: 'primary.contrastText',
              lineHeight: 1
            }}
          >
            FANTAMORTO
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={onMenuClick}
          sx={{ color: 'primary.contrastText' }}
        >
          <MenuIcon sx={{ fontSize: 32 }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;