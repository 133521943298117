import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Container, Paper, Card, CardContent, Dialog, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [news, setNews] = useState([]);
  const [loginOpen, setLoginOpen] = useState(false);
  const isLoggedIn = !!localStorage.getItem('token');

  useEffect(() => {
    // Fetch news
    const fetchNews = async () => {
      try {
        const response = await axios.get('/wp-json/wp/v2/posts');
        setNews(response.data);
      } catch (err) {
        console.error('Error fetching news:', err);
      }
    };
    fetchNews();
    
    // Show login dialog if not logged in
    if (!isLoggedIn) {
      setLoginOpen(true);
    }
  }, [isLoggedIn]);

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/wp-json/jwt-auth/v1/token', credentials);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        setLoginOpen(false);
        navigate('/team');
      }
    } catch (err) {
      setError('Credenziali non valide');
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Typography component="h1" variant="h4" color="primary" align="center" sx={{ mt: 4, mb: 2 }}>
        Fantamorto News
      </Typography>
      
      {news.map((post, index) => (
        <React.Fragment key={post.id}>
          <Card sx={{ mb: 2, bgcolor: 'secondary.dark' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box
                component="img"
                src={post.image_cloud?.medium || '/placeholder.jpg'}
                alt={post.title.rendered}
                sx={{
                  width: 90,
                  height: 90,
                  objectFit: 'cover',
                  flexShrink: 0
                }}
              />
              <CardContent>
                <Typography variant="h6" color="primary.light" gutterBottom>
                  {post.title.rendered}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                />
                <Typography variant="caption" color="text.secondary">
                  {new Date(post.date).toLocaleDateString('it-IT')}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </React.Fragment>
      ))}

      {/* Login Dialog */}
      <Dialog open={loginOpen} onClose={() => setLoginOpen(false)}>
        <DialogTitle color="secondary">Accedi a Fantamorto</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Username"
              name="username"
              value={credentials.username}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={credentials.password}
              onChange={handleChange}
            />
            {error && (
              <Typography color="error" align="center">
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Accedi
            </Button>
            <Typography align="center">
              Non hai un account?{' '}
              <Button color="secondary" onClick={() => navigate('/register')}>
                Registrati
              </Button>
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
      
      {!isLoggedIn && (
        <Box sx={{ position: 'fixed', bottom: 70, right: 20 }}>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={() => setLoginOpen(true)}
            sx={{ borderRadius: '50%', width: 60, height: 60 }}
          >
            Login
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default Login;