import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (formData.password !== formData.confirmPassword) {
      setError('Le password non coincidono');
      return;
    }

    try {
      console.log('Attempting registration...');
      // Using the application password endpoint
      const response = await api.post('/wp-json/wp/v2/users', {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        roles: ['subscriber']
      }, {
        headers: {
          'X-WP-Nonce': true // Enable nonce verification
        }
      });
      
      console.log('Registration response:', response.data);
      
      if (response.data.id) {
        console.log('Registration successful, attempting login...');
        const loginResponse = await api.post('/wp-json/jwt-auth/v1/token', {
          username: formData.username,
          password: formData.password
        });
        
        if (loginResponse.data.token) {
          localStorage.setItem('token', loginResponse.data.token);
          navigate('/');
        }
      }
    } catch (err) {
      console.error('Registration error details:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status
      });
      
      switch(err.response?.status) {
        case 404:
          setError('Il servizio di registrazione non è disponibile al momento.');
          break;
        case 403:
          setError('Non hai i permessi per effettuare la registrazione.');
          break;
        case 400:
          setError(err.response.data.message || 'Dati di registrazione non validi.');
          break;
        default:
          setError('Errore durante la registrazione. Contatta l\'amministratore.');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ p: 4, mt: 8 }}>
        <Typography component="h1" variant="h6" color='secondary' align="center">
          Registrati al Fantamorto
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Conferma Password"
            name="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
          />
          {error && (
            <Typography color="error" align="center">
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Registrati
          </Button>
          <Typography align="center">
            Hai già un account?{' '}
            <Button color="primary" onClick={() => navigate('/')}>
              Accedi
            </Button>
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default Register;